import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/agriculture/hay-forage/9000-series-self-propelled-forage-harvesters/',
    imageId: 'spfhImage',
    name: '9000 Series Self-Propelled Forage Harvesters',
    alt: 'John Deere 9000 Series Self-Propelled Forage Harvester',
  },
  {
    link: '/agriculture/hay-forage/small-square-balers/',
    imageId: 'smallSquareBalersImage',
    name: 'Small Square Balers',
    alt: 'John Deere Small Square Balers',
  },
  {
    link: '/agriculture/hay-forage/1-series-balers/',
    imageId: 'roundBalersImage',
    name: '1-Series Round Balers',
    alt: 'John Deere 1-Series Round Balers',
  },
  {
    link: '/agriculture/hay-forage/wrapping-balers/',
    imageId: 'wrappingBalersImage',
    name: 'Wrapping Round Balers',
    alt: 'John Deere Wrapping Round Balers',
  },
  {
    link: '/agriculture/hay-forage/mower-conditioners/',
    imageId: 'mocosImage',
    name: 'Mower Conditioners',
    alt: 'John Deere Mower Conditioners',
  },
  {
    link: '/agriculture/hay-forage/disc-mowers/',
    imageId: 'discMowersImage',
    name: 'Disc Mowers',
    alt: 'John Deere Disc Mowers',
  },
  {
    link: '/agriculture/hay-forage/hay-tedders/',
    imageId: 'teddersImage',
    name: 'Hay Tedders',
    alt: 'John Deere Hay Tedders',
  },
  {
    link: '/agriculture/hay-forage/rakes/',
    imageId: 'rakesImage',
    name: 'Rakes',
    alt: 'John Deere Hay Rakes',
  },
]

const HayForagePage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Hay &amp; Forage Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='When it comes to your John Deere hay and forage equipment, Hutson Inc has you covered. Providing you equipment that helps to get the most from your hay fields.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Hay & Forage',
                'item': 'https://www.hutsoninc.com/agriculture/hay-forage/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Hay &amp; Forage' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Hay &amp; Forage Equipment' />

        <Videos
          type='Hay &amp; Forage Equipment'
          id='8mFaF9e_z8U'
          list='PLUz5fj7f_mw-h3CZR93d6T-PFY0rqWQH8'
        />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query hayForageSubcategoryQuery($subcategory: String = "hay-forage") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/hay-forage-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    spfhImage: file(relativePath: { eq: "agriculture/9900-self-propelled-forage-harvester.jpg" }) {
      ...FloatingGridImage
    }
    smallSquareBalersImage: file(relativePath: { eq: "agriculture/small-square-balers.jpg" }) {
      ...FloatingGridImage
    }
    roundBalersImage: file(relativePath: { eq: "agriculture/0-series-balers.jpg" }) {
      ...FloatingGridImage
    }
    wrappingBalersImage: file(relativePath: { eq: "agriculture/wrapping-balers.jpg" }) {
      ...FloatingGridImage
    }
    mocosImage: file(relativePath: { eq: "agriculture/mower-conditioners.jpg" }) {
      ...FloatingGridImage
    }
    discMowersImage: file(relativePath: { eq: "agriculture/disc-mowers.jpg" }) {
      ...FloatingGridImage
    }
    teddersImage: file(relativePath: { eq: "agriculture/hay-tedder.jpg" }) {
      ...FloatingGridImage
    }
    rakesImage: file(relativePath: { eq: "agriculture/wheel-rakes.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default HayForagePage
